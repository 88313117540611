import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { withEuropeanUnionProjects } from './EuropeanUnionProjectsContext';
import HeroSection from './HeroSection';
import ProjectsSection from './ProjectsSection';
import { useDiscoverData } from './hooks';

import Layout from 'components/Layout';

const EuropeanUnionProjects: React.FC<Omit<PageProps, 'data'>> = () => {
  const { layoutProps } = useDiscoverData();

  return (
    <Layout {...layoutProps}>
      <HeroSection />
      <ProjectsSection />
    </Layout>
  );
};

export const europeanUnionProjectsFragment = graphql`
  fragment EuropeanUnionProjectsData on PrismicEuropeanUnionProjects {
    ...EuropeanUnionProjectsHeroData
    ...EuropeanUnionProjectsProjectsData
  }

  fragment EuropeanUnionProjectsLayoutData on PrismicEuropeanUnionProjects {
    lang
    alternate_languages {
      document {
        ... on PrismicEuropeanUnionProjects {
          lang
          url
        }
      }
    }
  }
`;

export default withEuropeanUnionProjects(EuropeanUnionProjects);
