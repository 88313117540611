import { useMemo } from 'react';
import { Elements } from 'prismic-reactjs';

import { useEuropeanUnionProjects } from '../EuropeanUnionProjectsContext';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useProjectsData = () => {
  const { projects: rawProjects, projects_image: projectsImage } =
    useEuropeanUnionProjects().euProjects.data;

  const { gatsbyImageData, alt } = projectsImage;

  const projects = useMemo(
    () =>
      rawProjects.map(({ project_title: title, project_description: description }) => ({
        title: richTextWithPlaceholder(title, '', Elements.heading2),
        description: richTextWithPlaceholder(description, ''),
      })),
    [rawProjects]
  );

  return {
    projectsImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    projects,
  };
};
