import { useEuropeanUnionProjects } from './EuropeanUnionProjectsContext';

import { useLayoutProps, TAlternateLanguages } from 'components/Layout';

export const useDiscoverData = () => {
  const { lang, alternate_languages, data } = useEuropeanUnionProjects().euProjects;

  return {
    layoutProps: useLayoutProps({
      lang,
      alternate_languages: alternate_languages as TAlternateLanguages,
      isDark: false,
    }),
    data,
  };
};
