import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { RichTextBlock } from 'prismic-reactjs';

import { HERO_SECTION_VARIABLES } from './HeroSection';

import { useEuropeanUnionProjects } from '../EuropeanUnionProjectsContext';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

const useHeroTitleScaling = () => {
  const [resizeId, setResizeId] = useState(0);
  const titleContainerRef = useRef<HTMLDivElement>(null);
  const titleRefs = useRef<Record<string, HTMLDivElement>>({});

  const getTitleRef: (key: string) => React.LegacyRef<HTMLDivElement> = key => element => {
    if (!titleRefs.current[key] && element) {
      titleRefs.current[key] = element;
    }
  };

  useEffect(() => {
    const handleResize = () => setResizeId(id => id + 1);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    if (titleRefs.current && titleContainerRef.current) {
      const titleContainerWidth = titleContainerRef.current.offsetWidth;

      const titleElements = Object.values(titleRefs.current);

      titleElements.forEach(element => {
        if (element) {
          const titleWidth = element.offsetWidth;

          if (titleWidth > titleContainerWidth) {
            element.setAttribute(
              'style',
              `${HERO_SECTION_VARIABLES.titleTextScale}: ${titleContainerWidth / (titleWidth + 8)};`
            );
          } else {
            element.setAttribute('style', `${HERO_SECTION_VARIABLES.titleTextScale}: 1;`);
          }
        }
      });
    }
  }, [resizeId]);

  return { titleContainerRef, getTitleRef };
};

export const useHeroData = () => {
  const {
    title: rawTitle,
    description,
    hero_image: heroImage,
  } = useEuropeanUnionProjects().euProjects.data;

  const { gatsbyImageData, alt } = heroImage;

  const title: RichTextBlock[][] = useMemo(
    () => splitRichTextByNewLine(richTextWithPlaceholder(rawTitle, '')),
    [rawTitle]
  );

  return {
    title,
    description: richTextWithPlaceholder(description, ''),
    heroImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    ...useHeroTitleScaling(),
  };
};
