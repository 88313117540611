import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import EuropeanUnionProjects from './EuropeanUnionProjects';

import { withHead } from 'components/SEO';

export const Head = withHead<Queries.EuropeanUnionProjectsQuery>('euProjects');

export const query = graphql`
  query EuropeanUnionProjects($id: String) {
    euProjects: prismicEuropeanUnionProjects(id: { eq: $id }) {
      ...SEOData
      ...EuropeanUnionProjectsData
      ...EuropeanUnionProjectsLayoutData
      _previewable
    }
  }
`;

export type AllEuropeanUnionProjectsQuery = Queries.AllEuropeanUnionProjectsQuery;

export default withPrismicPreview(EuropeanUnionProjects);
